/******* Customized Bootstrap ********/

$primary: #3f3f42;
$secondary: #a4a4a6;
$success: #e2e0da;
$light: #f7faff;
$dark: #000000;
$footer-bg: #282828;

$font-family-base: 'tt_firs_neueregular', sans-serif;

$headings-font-family: 'tt_firs_neueregular', sans-serif;

$body-bg: #ffffff;

$body-color: #3f3f42;

$headings-color: $dark;

$display-font-weight: 700;

$enable-responsive-font-sizes: true;

$border-radius: 10px;

$link-decoration: none;

$enable-negative-margins: true;

@import 'bootstrap/scss/bootstrap';
